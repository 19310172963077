@tailwind base;
@tailwind components;
@tailwind utilities;

body {
  margin: 0;
}


.bg-image {
  @apply bg-no-repeat bg-cover bg-center;
}

